import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import SideSlideMenu from "./SideSlideMenu";
import Logo from "../img/logo.png"
import React, { useState } from 'react';

import '../scss/header.scss';
import { motion, AnimatePresence } from "motion/react"




function Header() {

    const handleClick = (event) => {
        event.preventDefault(); // Impede o comportamento padrão
        window.open('https://wa.me/5596984062933?text=Ol%C3%A1%2C%20quero%20um%20delivery', '_blank'); // Abre o link em uma nova aba
    };

    const [isOpen, setIsOpen] = useState(false);
    const [origin, setOrigin] = useState("header"); // Definindo a origem (pode ser "header" ou "sticky")
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return (

        <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center', alignItems: 'center', minWidth: '100vw', width: '100vw', maxWidth: '100vw', position: 'absolute', zIndex: '9999' }}>
            <div className="headerContainer">
                <motion.div initial={{ y: -200, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: .5, delay: .2, ease: [0, 0.71, 0.2, 1.01] }} className="preHeader">
                    <div className="timewpp" >
                        <span><FontAwesomeIcon icon={faClock} style={{ color: '#0098DA' }} />&nbsp;&nbsp;08:00 - 18:00</span>
                        <span style={{ cursor: 'pointer' }} onClick={handleClick}><FontAwesomeIcon icon={faWhatsapp} style={{ color: '#0098DA' }} />&nbsp;&nbsp;96 98406-2933</span>
                    </div>
                    <div className="preHeaderSocialButton">
                        <a href="https://www.instagram.com/shekinahlavanderia/"><FontAwesomeIcon icon={faInstagram} style={{ color: '#0098DA' }} /></a>
                        <a href="https://www.facebook.com/LavanderiaShekinahmcp"><FontAwesomeIcon icon={faFacebook} style={{ color: '#0098DA' }} /></a>
                        <a href="https://wa.me/5596984062933?text=Ol%C3%A1%2C%20quero%20um%20delivery"><FontAwesomeIcon icon={faWhatsapp} style={{ color: '#0098DA' }} /></a>
                    </div>
                </motion.div>

                <motion.header initial={{ y: -200, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: .5, ease: [0, 0.71, 0.2, 1.01] }} >
                    <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', alignItems: 'center', gap: '2em' }}>
                        <div>
                            <img src={Logo} Width='210px' alt="" />
                        </div>

                        <nav className="navHeader">
                            <a href="inicio">Início</a>
                            <div
                                className="dropdown"
                                onMouseEnter={() => setIsDropdownOpen(true)}
                                onMouseLeave={() => setIsDropdownOpen(false)}
                                style={{ position: "relative", display: "inline-block" }}
                            >
                                {/* Botão do dropdown */}
                                <span style={{ cursor: 'pointer' }}>Serviços</span>

                                {/* Menu dropdown */}
                                <AnimatePresence>
                                    {isDropdownOpen && (
                                        <motion.div
                                            className="dropdown-content"
                                            initial={{ opacity: 0, y: -10 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -10 }}
                                            transition={{ duration: 0.3 }}
                                            style={{
                                                position: "absolute",
                                                top: "100%",
                                                marginTop: '.5em',
                                                left: 0,
                                                backgroundColor: "#fff",
                                                boxShadow: "0px 8px 16px rgba(0,0,0,0.2)",
                                                padding: "10px",
                                                display: 'flex',
                                                flexFlow: 'column nowrap',
                                                gap: '1em'
                                            }}
                                        >
                                        <a style={{ whiteSpace: 'nowrap' }} href="#limpeza">Lavagem e Higienização</a>
                                        <a style={{ whiteSpace: 'nowrap' }} href="#tinturaria">Tinturaria</a>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                            <a href="#sobre">Sobre nós</a>
                            <a href="#contato">Fale com a gente</a>
                        </nav>
                    </div>

                    <div style={{ display: 'flex', flexFlow: 'row nowrap', gap: '1em', justifyContent: 'center', alignItems: 'center' }}>
                        <button onClick={handleClick} className="btn btnBlue btnHeader">Agendar Delivery</button>
                        <div className="menuButton">
                            <div >
                                <FontAwesomeIcon icon={faBars} onClick={() => { setIsOpen(true); setOrigin("header"); }} style={{ fontSize: '2em', cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                </motion.header>
                <SideSlideMenu isOpen={isOpen} setIsOpen={setIsOpen} origin={origin}/>

            </div>
        </div>
    );
}

export default Header;