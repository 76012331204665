import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Logo from "../img/logo.png";
import ReactDOM from "react-dom";

function SideSlideMenu({ isOpen, setIsOpen, setIsStickyOpen, origin }) {
    useEffect(() => {
        if (isOpen) {
            // Lock scrolling
            document.body.style.overflow = "hidden";
        } else {
            // Unlock scrolling
            document.body.style.overflow = "auto";
        }

        return () => {
            // Ensure scrolling is unlocked when component unmounts
            document.body.style.overflow = "auto";
        };
    }, [isOpen]);

    const handleMenuStyle = (menu) => {
        if (menu) {
            menu.style.left = isOpen ? "0px" : "-85vw";
            menu.style.transition = "left 500ms ease";
        }
    };

    const handleClose = () => {
        if (origin === "header") {
            setIsOpen(false);
        } else if (origin === "sticky") {
            setIsStickyOpen(false);
        }
    };

    return ReactDOM.createPortal(
        <div>
            {/* Background Blur */}
            <div
                className={`side-slide-backdrop ${isOpen ? "open" : ""}`}
                onClick={handleClose} // Close menu when clicking outside
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    backdropFilter: "blur(8px)",
                    transition: "opacity 0.5s ease",
                    opacity: isOpen ? 1 : 0,
                    pointerEvents: isOpen ? "auto" : "none",
                    zIndex: 9999,
                }}
            ></div>

            {/* Slide Menu */}
            <nav
                className={`side-slide ${origin}`}
                ref={(menu) => handleMenuStyle(menu)}
                style={{
                    position: "fixed",
                    top: 0,
                    left: "-85vw",
                    height: "100vh",
                    width: "85vw",
                    zIndex: 10000,
                }}
            >
                <div>
                    <img src={Logo} width="210px" alt="Logo" />
                </div>
                <ul>
                    <a href="/">Início</a>
                    <a href="/">Serviços</a>
                    <a href="/">Sobre nós</a>
                    <a href="/">Fale com a gente</a>
                </ul>
                <button className="btn btnWhite">Solicitar delivery</button>
                <FontAwesomeIcon
                    className="navclose"
                    icon={faXmark}
                    onClick={handleClose} // Close menu on button click
                    style={{
                        cursor: "pointer",
                        fontSize: "1.5em",
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                    }}
                />
            </nav>
        </div>,
        document.body
    );
}

export default SideSlideMenu;
