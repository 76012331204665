import React, { useState } from 'react';

function ShorterForm() {
  const [formData, setFormData] = useState({
    nome: '',
    whatsapp: '',
    endereco: '',
    quant: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Capitalização do campo "nome"
    if (name === 'nome') {
      setFormData({ ...formData, [name]: value.replace(/\b\w/g, (char) => char.toUpperCase()) });
    } 
    // Máscara para o campo "whatsapp"
    else if (name === 'whatsapp') {
      let maskedValue = value.replace(/\D/g, '');

      if (maskedValue.length <= 2) {
        maskedValue = `(${maskedValue}`;
      } else if (maskedValue.length <= 7) {
        maskedValue = `(${maskedValue.slice(0, 2)}) ${maskedValue.slice(2)}`;
      } else if (maskedValue.length <= 11) {
        maskedValue = `(${maskedValue.slice(0, 2)}) ${maskedValue.slice(2, 7)}-${maskedValue.slice(7)}`;
      } else {
        maskedValue = `(${maskedValue.slice(0, 2)}) ${maskedValue.slice(2, 7)}-${maskedValue.slice(7, 11)}`;
      }

      setFormData({ ...formData, [name]: maskedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://lavanderiashekinah.com.br/api/form', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        // Monta a mensagem para o WhatsApp
        const whatsappMessage = `Olá, meu nome é ${formData.nome}. Estou solicitando um delivery. Endereço: ${formData.endereco}. Quantidade de peças: ${formData.quant}.`;
        
        // Codifica a mensagem para ser usada na URL
        const encodedMessage = encodeURIComponent(whatsappMessage);

        // Número de telefone com o código do país (exemplo: "5569984062933" para o Brasil)
        const whatsappNumber = '5569984062933'; // Altere para o número de destino desejado

        // Abre o link do WhatsApp com a mensagem formatada
        window.open(`https://wa.me/${whatsappNumber}?text=${encodedMessage}`, '_blank');

        // Limpa o formulário após o envio
        setFormData({ nome: '', whatsapp: '', endereco: '', quant: '' });
      } else {
        alert('Erro ao enviar formulário');
      }
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flexColumn" style={{ gap: '1.5em', alignItems: 'flex-start', width: '100%' }}>
      <div className="flexRow flexColumnMobile input100" style={{ width: '100%', gap: '1.5em' }}>
        <input
          type="text"
          name="nome"
          value={formData.nome}
          onChange={handleChange}
          placeholder="Seu nome"
          id="nameformcta"
          style={{ width: '50%' }}
        />
        <input
          type="text"
          name="whatsapp"
          value={formData.whatsapp}
          onChange={handleChange}
          placeholder="Whatsapp"
          id="whatsappformcta"
          style={{ width: '50%' }}
        />
      </div>

      <div className="flexRow flexColumnMobile input100" style={{ width: '100%', gap: '1.5em' }}>
        <input
          type="text"
          name="endereco"
          value={formData.endereco}
          onChange={handleChange}
          placeholder="Seu endereço"
          id="addressformcta"
          style={{ width: '50%' }}
        />
        <select
          name="quant"
          value={formData.quant}
          onChange={handleChange}
          id="quant"
          style={{ width: '50%' }}
        >
          <option value="" disabled>Quantidade de peças</option>
          <option value="0a20">0 a 20 peças</option>
          <option value="20a50">20 a 50 peças</option>
          <option value="50a100">50 a 100 peças</option>
          <option value="mais100">mais de 100 peças</option>
        </select>
      </div>

      <button type="submit" className="btn btnWhite">Solicitar delivery</button>
    </form>
  );
}

export default ShorterForm;
