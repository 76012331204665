import React, { useState } from 'react';

function LongerForm() {
  const [formData, setFormData] = useState({
    nome: '',
    whatsapp: '',
    email: '',
    service: '',
    quant: '',
    detalhes: ''
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Se o campo for "nome", capitaliza a primeira letra de cada palavra
    if (name === 'nome') {
      setFormData({ ...formData, [name]: value.replace(/\b\w/g, (char) => char.toUpperCase()) });
    } 
    // Se o campo for "whatsapp", aplica a máscara
    else if (name === 'whatsapp') {
      // Remove tudo o que não é número
      let maskedValue = value.replace(/\D/g, '');

      // Aplica a máscara progressiva
      if (maskedValue.length <= 2) {
        maskedValue = `(${maskedValue}`;
      } else if (maskedValue.length <= 7) {
        maskedValue = `(${maskedValue.slice(0, 2)}) ${maskedValue.slice(2)}`;
      } else if (maskedValue.length <= 11) {
        maskedValue = `(${maskedValue.slice(0, 2)}) ${maskedValue.slice(2, 7)}-${maskedValue.slice(7)}`;
      } else {
        maskedValue = `(${maskedValue.slice(0, 2)}) ${maskedValue.slice(2, 7)}-${maskedValue.slice(7, 11)}`;
      }

      setFormData({ ...formData, [name]: maskedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verificar se os campos obrigatórios foram preenchidos
    if (!formData.nome || !formData.whatsapp || !formData.email || !formData.service || !formData.quant) {
      setError('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    // Se todos os campos obrigatórios estiverem preenchidos, envia os dados
    try {
      const response = await fetch('https://lavanderiashekinah.com.br/api/form-long', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        // Monta o texto da mensagem para o WhatsApp
        const whatsappMessage = `Olá, meu nome é ${formData.nome}, estou solicitando um delivery em ${formData.email}, Serviço: ${formData.service}, Quantidade: ${formData.quant}, Detalhes: ${formData.detalhes}`;

        // Codifica a mensagem para ser incluída na URL
        const encodedMessage = encodeURIComponent(whatsappMessage);

        // Número de telefone com código do país (exemplo: "5569984062933" para o Brasil)
        const whatsappNumber = '5569984062933'; // Altere para o número de destino desejado

        // Abre o link do WhatsApp com a mensagem formatada
        window.open(`https://wa.me/${whatsappNumber}?text=${encodedMessage}`, '_blank');

        // Limpa o formulário após o envio
        setFormData({
          nome: '',
          whatsapp: '',
          email: '',
          service: '',
          quant: '',
          detalhes: ''
        });
        setError('');
      } else {
        alert('Erro ao enviar formulário');
      }
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flexColumn" style={{ gap: '1.5em', alignItems: 'flex-start' }}>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <div className="flexRow" style={{ width: '100%', gap: '1.5em' }}>
        <input
          type="text"
          name="nome"
          value={formData.nome}
          onChange={handleChange}
          placeholder="Seu nome"
          id="nameformcta"
          style={{ width: '50%' }}
          required
        />
        <input
          type="text"
          name="whatsapp"
          value={formData.whatsapp}
          onChange={handleChange}
          placeholder="Whatsapp"
          id="whatsappformcta"
          style={{ width: '50%' }}
          required
        />
      </div>

      <input
        type="text"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Endereço para retirada e entrega"
        id="emailformcta"
        style={{ width: '100%', maxWidth: '100%' }}
        required
      />

      <div className="flexRow" style={{ width: '100%', gap: '1.5em' }}>
        <select
          className="minimal"
          name="service"
          value={formData.service}
          onChange={handleChange}
          id="service"
          style={{ width: '50%' }}
          required
        >
          <option value="" disabled>Selecione o serviço</option>
          <option value="lavanderia">Lavanderia</option>
          <option value="Tinturaria">Tinturaria</option>
        </select>

        <select
          name="quant"
          value={formData.quant}
          onChange={handleChange}
          id="quant"
          style={{ width: '50%' }}
          required
        >
          <option value="" disabled>Quantidade de peças</option>
          <option value="0a20">0 a 20 peças</option>
          <option value="20a50">20 a 50 peças</option>
          <option value="50a100">50 a 100 peças</option>
          <option value="mais100">mais de 100 peças</option>
        </select>
      </div>

      <textarea
        placeholder="Algum detalhe? Nos conte!"
        name="detalhes"
        value={formData.detalhes}
        onChange={handleChange}
        id="detailsformcta"
      />

      <button type="submit" className="btn btnWhite">Solicitar delivery</button>
    </form>
  );
}

export default LongerForm;
