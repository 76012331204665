import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from "../img/logo.png";
import React, { useState, useEffect } from 'react';
import SideSlideMenu from "./SideSlideMenu";
import '../scss/header.scss';
import { motion, AnimatePresence } from "framer-motion";

function HeaderSticky() {
    const [isStickyOpen, setIsStickyOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showHeader, setShowHeader] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [origin, setOrigin] = useState("sticky"); // Definindo a origem como "sticky"

    const hoverLink = {
        color: isHovered ? '#fff' : '#32424F',
    };

    // Função para detectar o scroll
    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const viewportHeight = window.innerHeight;

            // Mostrar o header se o scroll passou de 100vh
            if (scrollY > viewportHeight * 1) {
                setShowHeader(true);
            } else {
                setShowHeader(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <motion.div
            style={{
                position: 'fixed',
                top: 0,
                width: '100%',
                zIndex: 9999,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backdropFilter: 'blur(16px) saturate(180%)',
                WebkitBackdropFilter: 'blur(25px) saturate(180%)',
                backgroundColor: 'rgba(9, 37, 58, 0.75)',
                boxShadow: showHeader ? '0px 4px 12px rgba(0, 0, 0, 0.1)' : 'none',
            }}
            initial={{ y: -100, opacity: 0 }}
            animate={showHeader ? { y: 0, opacity: 1 } : { y: -100, opacity: 0 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
        >
            <header className="StickyHeader" style={{ display: 'flex', justifyContent: 'space-between', background: 'none' }}>
                <div style={{ display: 'flex', minWidth: '100%', justifyContent: 'space-between', alignItems: 'center', padding: '1em' }}>
                    <div>
                        <img src={Logo} width="210px" alt="Logo" />
                    </div>

                    <nav className="navHeader navSticky">
                        <a href="#inicio" style={hoverLink} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>Início</a>
                        <div
                            className="dropdown"
                            onMouseEnter={() => setIsDropdownOpen(true)}
                            onMouseLeave={() => setIsDropdownOpen(false)}
                            style={{ position: "relative", display: "inline-block" }}
                        >
                            <span  style={{ cursor: 'pointer' }}>Serviços</span>

                            <AnimatePresence>
                                {isDropdownOpen && (
                                    <motion.div
                                        className="dropdown-content"
                                        initial={{ opacity: 0, y: -10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -10 }}
                                        transition={{ duration: 0.3 }}
                                        style={{
                                            position: "absolute",
                                            top: "100%",
                                            marginTop: '.5em',
                                            left: 0,
                                            backgroundColor: "#fff",
                                            boxShadow: "0px 8px 16px rgba(0,0,0,0.2)",
                                            padding: "10px",
                                            display: 'flex',
                                            flexFlow: 'column nowrap',
                                            gap: '1em',
                                        }}
                                    >
                                        <a style={{ whiteSpace: 'nowrap' }} href="#limpeza">Lavagem e Higienização</a>
                                        <a style={{ whiteSpace: 'nowrap' }} href="#tinturaria">Tinturaria</a>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                        <a href="#sobre">Sobre nós</a>
                        <a href="#contato">Fale com a gente</a>
                    </nav>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
                        <button className="btn btnBlue btnHeader">Agendar Delivery</button>
                        <FontAwesomeIcon className="sideslideMenu" icon={faBars}  onClick={() => {setIsStickyOpen(true); setOrigin("sticky");
                    }} style={{ fontSize: '2em', cursor: 'pointer' }} />
                    </div>
                    <SideSlideMenu isOpen={isStickyOpen} setIsOpen={setIsStickyOpen} setIsStickyOpen={setIsStickyOpen} origin={origin}
/>

                </div>
            </header>
        </motion.div>
    );
}

export default HeaderSticky;
